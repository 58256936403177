

























































































































































































































































































































































































































































































import {Component, Mixins, Model, Prop, Watch} from 'vue-property-decorator';
import AdresInfo from '@/views/vestigingen/components/AdresInfo.vue';
import ContactCard from '@/views/vestigingen/components/ContactCard.vue';
import IdentificatieInfo from '@/views/vestigingen/components/IdentificatieInfo.vue';
import StartEindeInfo from '@/views/vestigingen/components/StartEindeInfo.vue';
import {buildEmptyWerknemerStatForPeriod, IWerknemerStat} from '@/models/IWerknemerStat';
import {ShowsMessages} from '@/mixins/ShowsMessages';
import {AuthorizationMixin} from '@/mixins/AuthorizationMixin';
import {IVestiging} from '@/models/IVestiging';
import {Idable} from '@/models/Idable';
import {IMeetperiode} from '@/models/constants/IMeetperiode';
import {min, required} from '@/utils/validation';
import {IGrootteklasse} from '@/models/constants/IGrootteklasse';
import {IWijzeBinnen} from '@/models/constants/IWijzeBinnen';
import {IEnqueteType} from '@/models/constants/IEnqueteType';

@Component({
    filters: {},
    components: {
        StartEindeInfo,
        IdentificatieInfo,
        ContactCard,
        AdresInfo,
    },
})
export default class WerkzamePersonen extends Mixins(ShowsMessages, AuthorizationMixin) {
    @Prop()
    public vestiging!: IVestiging;

    @Prop({type: Boolean, default: false})
    public useBevroren!: boolean;

    public isLoading = false;

    @Prop({type: Boolean, default: false})
    public canUpdate!: boolean;

    @Prop()
    public validationErrorsCount!: number;

    public allMeetPeriodes: Idable[] = [];
    public meetPeriodes: Idable[] = [];
    public enqueteTypeOptions: Idable[] = [];
    public grootteklasseOptions: Idable[] = [];
    public wijzeBinnenOptions: Idable[] = [];
    public selectedMeetPeriodes: Idable[] = [];
    public werknemerStats: IWerknemerStat[] = [];
    public werknemerStatsPersisted: IWerknemerStat[] = [];
    public strongWsChangeDialog: boolean = false;
    public strongChangedWs: IWerknemerStat[] | null = [];

    public currentPeriodStartIndex: number = 0;
    public currentPeriodEndIndex: number = 5;

    @Watch('validationErrorsCount')
    public onValidationErrorsInComponent() {
        const mainComponent = this.$refs.inputComponent;
        const invalidInput = (mainComponent as any).$children.find((child: any) => {
            return child.validations && child.validations.length > 0;
        });
        if (invalidInput) {
            this.$emit('invalidInput');
        }
    }

    @Watch('vestiging.werknemerStats', {deep: true})
    public onChangeWs() {
        this.strongChangedWs = this.findStrongChangedWs();
        this.strongWsChangeDialog = !!(this.strongChangedWs && this.strongChangedWs.length > 0);
    }

    public beforeMount() {
        this.werknemerStatsPersisted = this.vestiging.werknemerStats ? this.vestiging.werknemerStats : [];
    }

    public findStrongChangedWs(): IWerknemerStat[] | null {
        if (this.vestiging != null && this.vestiging.werknemerStats != null) {
            return this.vestiging.werknemerStats.filter((ws) => this.hasWpChangedStrongly(ws)).sort((w1, w2) => {
                const name1 = w1.meetperiode.naam.toLowerCase();
                const name2 = w2.meetperiode.naam.toLowerCase();
                if (name1 > name2) { return 1; }
                if (name1 < name2) { return -1; }
                return 0;
            });
        } else {
            return null;
        }
    }

    public hasValueChanged(edited: IWerknemerStat): boolean {
        const original = this.werknemerStatsPersisted.find((ws) => ws.id === edited.id);
        for (const key in original) {
            if ((key as string).includes('time') && original.hasOwnProperty(key)
                    && original[key as keyof IWerknemerStat] !== edited[key as keyof IWerknemerStat]) {
                return true;
            }
        }
        return false;
    }

    public hasWpChangedStrongly(next: IWerknemerStat): boolean {
        if (next && this.vestiging && this.vestiging.werknemerStats && !next.bevroren) {
            const hasCurrentChanged = this.hasValueChanged(next);
            const previous = this.findPreviousWs(next);
            const hasPreviousChanged = previous ? this.hasValueChanged(previous) : false;
            if (previous) {
                const totalPrevious = this.calculateTotallEmloyees(previous);
                if (!totalPrevious) {
                    // No previous data
                    return false;
                }
                const totalNext = this.calculateTotallEmloyees(next);
                const absoluteDifference = Math.abs(totalPrevious! - totalNext!);
                const percentageDiff = absoluteDifference / totalPrevious!;
                return absoluteDifference >= 10 && percentageDiff >= 0.1 && (hasCurrentChanged || hasPreviousChanged);
            }
        }
        return false;
    }

    public findPreviousWs(nextPeriod: IWerknemerStat) {
        if (!this.vestiging || !this.vestiging.werknemerStats) {
            return null;
        }
        return this.vestiging.werknemerStats.reduce((acc: IWerknemerStat | null, obj: IWerknemerStat) => {
            // obj should be before the nextPeriod
            if (obj.meetperiode.peildatumEndOfDay >= nextPeriod.meetperiode.peildatumEndOfDay) {
                return acc;
            }
            // obj should be after the current acc
            if (acc === null || acc.meetperiode.peildatumEndOfDay < obj.meetperiode.peildatumEndOfDay) {
                return obj;
            }
            return acc;
        }, null) || null;
    }

    public get rules() {
        return {
            aantalPersonenInput: [min(0)],
        };
    }

    public get selectedMeetPeriodeIds(): IMeetperiode[] | any[] {
        return this.selectedMeetPeriodes.map((meetperiode) => meetperiode.id);
    }

    public get hasPrev() {
        return this.currentPeriodEndIndex < this.meetPeriodes.length;
    }

    public get hasNext() {
        return this.currentPeriodEndIndex > 5;
    }

    public calculateGrootteKlasse(werknemerStat: IWerknemerStat) {
        const totallEmployees = this.calculateTotallEmloyees(werknemerStat);
        const grootteklasse = this.grootteklasseOptions.find((klasse) => {
            const grootteklasseObject = klasse as IGrootteklasse;
            return grootteklasseObject.ondergrens <= totallEmployees!
                    && grootteklasseObject.bovengrens >= totallEmployees!;
        });
        if (grootteklasse) {
            werknemerStat.grootteklasse = grootteklasse as IGrootteklasse;
        }
    }

    public calculateTotallEmloyees(werknemerStat: IWerknemerStat) {
        if (!werknemerStat) {
            return null;
        }
        let totallEmployees = 0;
        if (werknemerStat.manFulltime) {
            totallEmployees += werknemerStat.manFulltime;
        }
        if (werknemerStat.vrouwFulltime) {
            totallEmployees += werknemerStat.vrouwFulltime;
        }
        if (werknemerStat.manParttime) {
            totallEmployees += werknemerStat.manParttime;
        }
        if (werknemerStat.vrouwParttime) {
            totallEmployees += werknemerStat.vrouwParttime;
        }
        if (werknemerStat.manFulltimeUitzendkracht) {
            totallEmployees += werknemerStat.manFulltimeUitzendkracht;
        }
        if (werknemerStat.vrouwFulltimeUitzendkracht) {
            totallEmployees += werknemerStat.vrouwFulltimeUitzendkracht;
        }
        if (werknemerStat.manParttimeUitzendkracht) {
            totallEmployees += werknemerStat.manParttimeUitzendkracht;
        }
        if (werknemerStat.vrouwParttimeUitzendkracht) {
            totallEmployees += werknemerStat.vrouwParttimeUitzendkracht;
        }
        return totallEmployees;
    }

    public prevPeriod() {
        if (!this.hasPrev) {
            return;
        }
        this.currentPeriodEndIndex++;
        this.currentPeriodStartIndex++;
        this.selectedMeetPeriodes = this.meetPeriodes.slice(this.currentPeriodStartIndex, this.currentPeriodEndIndex);
    }

    public nextPeriod() {
        if (!this.hasNext) {
            return;
        }
        this.currentPeriodEndIndex--;
        this.currentPeriodStartIndex--;
        this.selectedMeetPeriodes = this.meetPeriodes.slice(this.currentPeriodStartIndex, this.currentPeriodEndIndex);
    }

    public get filteredWerknemerStats() {
        return (this.werknemerStats.filter((werknemerStat) => {
            // When bevroren-tab is selected, the bevroren data should always be shown, despite start/end dates
            if (this.useBevroren) {
                return werknemerStat.bevroren;
            }
            // when bevroren-tab is not selected, the bevroren data should never be shown.
            if (werknemerStat.bevroren) {
                return false;
            }

            const meetPeriodeEntry = Object.entries(werknemerStat).find((pair) => pair[0] === 'meetperiode');
            const periode: IMeetperiode = meetPeriodeEntry ? meetPeriodeEntry[1] : null as any;
            // If the vestiging was active for (less than) a day, then it never has been active.
            if (this.vestiging.datumEinde
                && this.vestiging.datumStart
                && this.vestiging.datumEinde - this.vestiging.datumStart < 86400
            ) {
                return false;
            }
            if (this.vestiging.datumStart && !this.useBevroren) {
                return this.vestiging.datumStart <= periode.peildatumEndOfDay
                    && (!this.vestiging.datumEinde || this.vestiging.datumEinde > periode.peildatumEndOfDay);
            } else {
                return periode && this.selectedMeetPeriodeIds.includes(periode.id as any);
            }
        }) || []).sort((a: any|IWerknemerStat, b: any|IWerknemerStat) => {
            const aEntry = Object.entries(a).find((pair) => pair[0] === 'meetperiode');
            const aPeriode: IMeetperiode = aEntry ? aEntry[1] : null as any;
            const bEntry = Object.entries(b).find((pair) => pair[0] === 'meetperiode');
            const bPeriode: IMeetperiode = bEntry ? bEntry[1] : null as any;
            if (aPeriode.peildatumStartOfDay < bPeriode.peildatumStartOfDay) {
                return 1;
            }
            if (aPeriode.peildatumStartOfDay > bPeriode.peildatumStartOfDay) {
                return -1;
            }
            return 0;
        });
    }

    public async created() {
        await this.loadData();
        this.wijzeBinnenOptions = (await this.$api.wijzeBinnen.listAll()).data?.content || [];
        this.enqueteTypeOptions = (await this.$api.enqueteType.listAll()).data?.content || [];
        this.grootteklasseOptions = (await this.$api.grootteklasse.listAll()).data?.content || [];

        this.selectedMeetPeriodes = this.meetPeriodes.slice(this.currentPeriodStartIndex, this.currentPeriodEndIndex);
    }

    public async loadData() {
        try {
            this.allMeetPeriodes = (await this.$api.meetperiode.listAll(
                [],
                'peildatumEndOfDay',
                'DESC')
            ).data?.content || [];
        } catch (e) {
            console.error('Er is een fout opgetreden bij het laden van de data.', e);
            this.showError('Er is een fout opgetreden bij het laden van de data.');
        }
        await this.setMeetPeriodes();
    }

    @Watch('vestiging.datumStart')
    @Watch('vestiging.datumEinde')
    public async setMeetPeriodes() {
        this.meetPeriodes = this.allMeetPeriodes.filter((periode) => {
            if (this.vestiging.datumEinde
                && !this.useBevroren
                && this.vestiging.datumStart
                && this.vestiging.datumEinde - this.vestiging.datumStart < 86400
            ) {
                return false;
            }
            if (this.vestiging.datumStart && !this.useBevroren) {
                const p: IMeetperiode = periode as IMeetperiode;
                return this.vestiging.datumStart <= p.peildatumEndOfDay
                    && (!this.vestiging.datumEinde || this.vestiging.datumEinde > p.peildatumEndOfDay);
            } else if (this.useBevroren) {
                    const meetperiodeIdsBevroren = this.werknemerStatsPersisted.filter(
                            (ws) => ws.bevroren).map((ws) => ws.meetperiode.id);
                    return meetperiodeIdsBevroren.includes(periode.id);
            } else {
                return true;
            }
        });
        let werknemerStats = [] as any;
        if (this.$route.name === 'vestigingEdit') {
            const response = await this.$api.vestiging.werknemerStats(this.vestiging.id);
            werknemerStats = await (await response.data!).content as unknown as IWerknemerStat[];
        }

        if (!this.defaultGrootteklasse || !this.defaultWijzeBinnen) {
            setTimeout(this.setMeetPeriodes, 200);
            return;
        }

        for (const meetperiode of this.meetPeriodes) {
            if (!this.useBevroren && !werknemerStats.find((werknemerStat: any) => {
                // FIXME: I just can't wrap my head around it -> The werknemerStat.meetPeriod has a value when you
                //  output it in console.log, but it is undefined in code because of Observable.
                //  it doesn't make sense (to me), since the data should be ready, so why is it undefined?
                //  Using "Object.entries(werknemerStat)" is a roundabout way to at least retrieve the actual value.
                const meetPeriodeEntry = Object.entries(werknemerStat).find((pair) => pair[0] === 'meetperiode');
                const periode: IMeetperiode = meetPeriodeEntry ? meetPeriodeEntry[1] : null as any;

                return periode && meetperiode && periode.id === meetperiode.id && !werknemerStat.bevroren;
            })) {
                werknemerStats.push(buildEmptyWerknemerStatForPeriod(
                    meetperiode as any,
                    this.useBevroren,
                    this.defaultEnqueteType,
                    this.defaultGrootteklasse,
                    this.defaultWijzeBinnen,
                ));
            }
        }
        this.werknemerStats = werknemerStats;
        if (!this.useBevroren) {
            this.vestiging.werknemerStats = werknemerStats;
        }
        this.selectedMeetPeriodes = this.meetPeriodes.slice(this.currentPeriodStartIndex, this.currentPeriodEndIndex);
    }

    public get defaultEnqueteType(): null|IEnqueteType {
        return (this.enqueteTypeOptions as IEnqueteType[]).find((enqueteType) => enqueteType.code = '00') || null;
    }

    public get defaultWijzeBinnen(): null|IWijzeBinnen {
        return (this.wijzeBinnenOptions as IWijzeBinnen[]).find((wijzeBinnen) => wijzeBinnen.code = '00') || null;
    }

    public get defaultGrootteklasse(): null|IGrootteklasse {
        return (this.grootteklasseOptions as IGrootteklasse[])
            .find((grootteklasse) => grootteklasse.code = '01') || null;
    }

    public get isDisabled() {
        return !this.canUpdate;
    }

    public isReadOnly(ws: IWerknemerStat) {
        return ws.bevroren;
    }

    public getClassesForBevroren(item: IWerknemerStat) {
        return this.useBevroren && item.bevroren && !item.id ? 'text--disabled' : '';
    }

    public getGemeenteNaam(item: IWerknemerStat) {
        if (item.adres) {
            const gemeenteNaam = item.adres.gemeente ? item.adres.gemeente.omschrijving : '';
            const gemeente = item.adres.gemeente ? item.adres.gemeente.code : '';
            return item.adres ? gemeenteNaam + '(' + gemeente  + ')' : '';
        } else {
            return '';
        }
    }

    public castToWStat(meetperiode: IMeetperiode): IWerknemerStat {
        if (this.vestiging.werknemerStats) {
            for (const werknStat of this.vestiging.werknemerStats) {
                if (werknStat.meetperiode.id === meetperiode.id) {
                    return werknStat;
                }
            }
        }
        return buildEmptyWerknemerStatForPeriod(
            meetperiode as IMeetperiode,
            this.useBevroren,
            this.defaultEnqueteType,
            this.defaultGrootteklasse,
            this.defaultWijzeBinnen,
        );
    }
}
