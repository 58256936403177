import {AuthenticationApi} from '@/api/auth';
import {GenericApi} from '@/api/generic';
import {IGebruiker} from '@/models/IGebruiker';
import {Idable} from '@/models/Idable';
import {KvkApi} from '@/api/kvk';
import {IRegister} from '@/models/IRegister';
import {ImportTemplateApi} from '@/api/importTemplate';
import {ImportApi} from '@/api/import';
import {VestigingApi} from '@/api/vestiging';
import {ExportApi} from '@/api/Export';
import {ConstantIdToMutationInfo} from '@/api/constantIdToMutationInfo';
import {GenericConstantApi} from '@/api/genericConstant';
import {KvkUploadRunApi} from '@/api/kvkUploadRun';
import {KvkMutatieApi} from '@/api/kvkMutatie';
import {BagAdresApi} from '@/api/bagAdres';
import {MeetperiodeApi} from '@/api/meetperiode';
import {WerknemerStatApi} from '@/api/werknemerStat';
import { BagApi } from '@/api/bag';
import { ShapeApi } from '@/api/shape';
import {IMutatieSoort} from '@/models/IMutatieSoort';
import {CbApi} from '@/api/cb';
import {MutatieApi} from '@/api/mutatie';
import {BuurtApi} from '@/api/buurt';
import {Bedrijventerrein} from '@/api/bedrijventerrein';
import {IOverheidsCode} from '@/models/constants/IOverheidsCode';
import {KvkSignalApi} from '@/api/kvkSignal';
import {KvkSignalVestigingApi} from '@/api/kvkSignalVestiging';

/**
 * Grouping class for all APIs
 */
export class Api {
    public readonly auth: AuthenticationApi;
    public readonly user = new GenericApi<IGebruiker>('/api/v1/gebruiker');
    public readonly vestiging = new VestigingApi('/api/v1/vestiging');
    public readonly cb = new CbApi();
    public readonly mutatie = new MutatieApi();
    public readonly mutatieSoort = new GenericApi<IMutatieSoort>('/api/v1/mutatie-soort');
    public readonly importTemplate = new ImportTemplateApi();
    public readonly constantIdToMutationIfo = new ConstantIdToMutationInfo();
    public readonly register = new GenericApi<IRegister>('/api/v1/register');
    public readonly import = new ImportApi();
    public readonly kvk = new KvkApi('/api/v1/kvk');
    public readonly kvkSignal = new KvkSignalApi('/api/v1/kvk-signal');
    public readonly kvkSignalVestiging = new KvkSignalVestigingApi('/api/v1/kvk-signal-vestiging');
    public readonly bag = new BagApi();
    public readonly shape = new ShapeApi();
    public readonly bagAdres = new BagAdresApi('/api/v1/bag');
    public readonly kvkUploadRun = new KvkUploadRunApi();
    public readonly kvkMutatie = new KvkMutatieApi();
    public readonly export = new ExportApi();
    public readonly meetperiode = new MeetperiodeApi();
    public readonly werknemerStat = new WerknemerStatApi();
    public readonly kvkdata = new KvkApi('/api/v1/kvk-data');

    public readonly sbi = new GenericConstantApi<Idable>('/api/v1/sbi');
    public readonly grootteklasse = new GenericConstantApi<Idable>('/api/v1/grootteklasse');
    public readonly enqueteType = new GenericConstantApi<Idable>('/api/v1/enquete-type');
    public readonly gebruiksfunctie = new GenericConstantApi<Idable>('/api/v1/gebruiksfunctie');
    public readonly gemeente = new GenericConstantApi<Idable>('/api/v1/gemeente');
    public readonly kvkHandmatigCode = new GenericConstantApi<Idable>('/api/v1/kvk-handmatig-code');
    public readonly kvkRedenIn = new GenericConstantApi<Idable>('/api/v1/kvk-reden-in');
    public readonly kvkRedenUit = new GenericConstantApi<Idable>('/api/v1/kvk-reden-uit');
    public readonly overheidsCode = new GenericConstantApi<IOverheidsCode>('/api/v1/overheids-code');
    public readonly plaatsnaam = new GenericConstantApi<Idable>('/api/v1/plaatsnaam');
    public readonly behandelingType = new GenericConstantApi<Idable>('/api/v1/behandeling-type');
    public readonly rechtsvorm = new GenericConstantApi<Idable>('/api/v1/rechtsvorm');
    public readonly typePand = new GenericConstantApi<Idable>('/api/v1/type-pand');
    public readonly buurt = new BuurtApi();
    public readonly wijk = new GenericConstantApi<Idable>('/api/v1/wijk');
    public readonly bedrijventerrein = new Bedrijventerrein();

    public readonly uitzetCode = new GenericConstantApi<Idable>('/api/v1/uitzet-code');
    public readonly vestigingType = new GenericConstantApi<Idable>('/api/v1/vestiging-type');
    public readonly wijzeBinnen = new GenericConstantApi<Idable>('/api/v1/wijze-binnen');
    public readonly wijzeEinde = new GenericConstantApi<Idable>('/api/v1/wijze-einde');
    public readonly wijzeStart = new GenericConstantApi<Idable>('/api/v1/wijze-start');
    public readonly xyKwaliteit = new GenericConstantApi<Idable>('/api/v1/xy-kwaliteit');

    constructor() {
        this.auth = new AuthenticationApi();
    }
}

export default Api;
