











































































































import {Component, Mixins, Watch} from 'vue-property-decorator';
import {IKvkSignal} from '@/models/IKvkSignal';
import {IVestiging} from '@/models/IVestiging';
import {Pagination} from '@/utils/api-tools/pagination';
import {SearchCriterion} from '@/utils/api-tools/search-criteria';
import {ListsObjects} from '@/mixins/ListsObjects';
import {ShowsMessages} from '@/mixins/ShowsMessages';
import {AuthorizationMixin} from '@/mixins/AuthorizationMixin';
import {date, dateTime} from '@/utils/filters';

interface IVestigingReference {
    vestigingsnummer: string;
    id: number | string | null;
}

@Component({
    filters: {
        dateTime,
    },
})
export default class KvkSignalView extends Mixins(ListsObjects, ShowsMessages, AuthorizationMixin) {
    public subjectId: number|string|null = null;
    public kvkSignal: IKvkSignal | null = null;
    public vestigingen: IVestiging[] = [];

    public get api() {
        return this.$api.kvkSignal;
    }

    public get vestigingenApi() {
        return this.$api.vestiging;
    }

    public get parsedSignalData(): any {
        if (!this.kvkSignal?.signaalJson) {
            return null;
        }
        try {
            return JSON.parse(this.kvkSignal.signaalJson);
        } catch (e) {
            return this.kvkSignal.signaalJson;
        }
    }

    public get signalData(): string {
        if (!this.parsedSignalData) {
            return '';
        }
        try {
            return JSON.stringify(this.parsedSignalData, null, 2);
        } catch (e) {
            return this.kvkSignal?.signaalJson || '';
        }
    }

    public get vestigingNummers(): string[] {
        return this.parsedSignalData
            ?.heeftBetrekkingOp
            ?.wordtUitgeoefendIn
            ?.filter((v: any) => !!v.vestigingsnummer)
            ?.map((v: any) => v.vestigingsnummer)
            ?? [];
    }

    public get vestigingReferences(): IVestigingReference[] {
        return this.vestigingNummers.map((v) => {
            const vestiging = this.vestigingen.find((t) => t.vestigingNummer === parseInt(v, 10));
            return {
                vestigingsnummer: v,
                id: vestiging?.id ?? null,
            };
        });
    }

    public get vestigingenData(): string {
        if (!this.kvkSignal?.vestigingenJson) {
            return '';
        }
        return JSON.stringify(JSON.parse(this.kvkSignal.vestigingenJson), null, 2);
    }

    public async created() {
        this.subjectId = this.$route.params.id;
        this.kvkSignal = (await this.api.get(this.subjectId)).data!;
    }
}
