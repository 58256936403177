

































































































import {Component, Mixins} from 'vue-property-decorator';
import StatusColorChip from '@/components/StatusColorChip.vue';
import AutoLoadingButton from '@/components/AutoLoadingButton.vue';
import SimpleDeleteDialog from '@/components/SimpleDeleteDialog.vue';
import SimpleMessageWrapper from '@/components/SimpleMessageWrapper.vue';
import {ListsObjects} from '@/mixins/ListsObjects';
import {AuthorizationMixin} from '@/mixins/AuthorizationMixin';
import {State} from 'vuex-class';
import {IGebruiker} from '@/models/IGebruiker';
import {date, dateTime} from '@/utils/filters';
import {IKvkSignalServicesStatusInfo} from '@/models/IKvkSignalServicesStatusInfo';
import KvkSignalServices from '@/views/kvk-signal/KvkSignalServices.vue';

@Component({
    filters: {date, dateTime},
    components: {KvkSignalServices, StatusColorChip, AutoLoadingButton, SimpleDeleteDialog, SimpleMessageWrapper},
})
export default class KvkSignalList extends Mixins(ListsObjects, AuthorizationMixin) {
    public headers: any[] = [
        {text: 'ID', value: 'id'},
        {text: 'Timestamp', value: 'timestamp'},
        {text: 'KvK-nummer', value: 'kvkNummer'},
        {text: 'Vestigingnummer(s)', value: 'vestigingnummers', sortable: false},
        {text: 'Pogingen details', value: 'detailsAttempts'},
        {text: 'Gelukt details', value: 'detailsSuccess'},
        {text: 'Reden indien mislukt', value: 'failureReason'},
    ];

    public statusInfo: IKvkSignalServicesStatusInfo | null = null;

    @State((state: any) => state.authentication.user)
    public currentUser!: IGebruiker;

    public get api() {
        return this.$api.kvkSignal;
    }

    public toEdit(object: string|number|any) {
        this.$router.push({name: 'kvk_signal_view', params: { id: object.id.toString() }});
    }
}
