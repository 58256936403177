










































































































import {Component, Mixins} from 'vue-property-decorator';
import {IVestiging} from '@/models/IVestiging';
import {ListsObjects} from '@/mixins/ListsObjects';
import {ShowsMessages} from '@/mixins/ShowsMessages';
import {AuthorizationMixin} from '@/mixins/AuthorizationMixin';
import {IKvkSignalVestiging} from '@/models/IKvkSignalVestiging';

@Component({})
export default class KvkSignalVestigingView extends Mixins(ListsObjects, ShowsMessages, AuthorizationMixin) {
    public subjectId: number|string|null = null;
    public kvkSignalVestiging: IKvkSignalVestiging | null = null;
    public vestigingen: IVestiging[] = [];

    public get api() {
        return this.$api.kvkSignalVestiging;
    }

    public get vestigingenData(): string {
        if (!this.kvkSignalVestiging?.vestigingJson) {
            return '';
        }
        try {
            return JSON.stringify(JSON.parse(this.kvkSignalVestiging.vestigingJson), null, 2);
        } catch (e) {
            return this.kvkSignalVestiging.vestigingJson;
        }
    }

    public async created() {
        this.subjectId = this.$route.params.id;
        this.kvkSignalVestiging = (await this.api.get(this.subjectId)).data!;
    }
}
