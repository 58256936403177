





















































































































































































































































































































import SimpleDeleteDialog from '@/components/SimpleDeleteDialog.vue';
import SimpleMessageWrapper from '@/components/SimpleMessageWrapper.vue';
import {ListsObjects} from '@/mixins/ListsObjects';
import {Component, Mixins, Watch} from 'vue-property-decorator';
import AutoLoadingButton from '@/components/AutoLoadingButton.vue';
import {AuthorizationMixin} from '@/mixins/AuthorizationMixin';
import StatusColorChip from '@/components/StatusColorChip.vue';
import {State} from 'vuex-class';
import {IGebruiker} from '@/models/IGebruiker';
import KvkInfo from '@/views/kvk/KvkInfo.vue';
import {bevatDatumEindeOptions, bevatWijzeEindeOptions, IKvk, KvkStatus, kvkStatusOptions} from '@/models/IKvk';
import {number} from '@/utils/validation';
import {ShowsMessages} from '@/mixins/ShowsMessages';
import {ExportJobStatus} from '@/models/IExportRun';
import {url} from '@/utils/http';
import SimpleCardDialog from '@/components/SimpleCardDialog.vue';
import {dateTime} from '@/utils/filters';
import {Idable} from '@/models/Idable';
import {IRegister} from '@/models/IRegister';
import {IExportKvkCreate} from '@/models/ExportKvkCreate';
import {IKvkExportRun} from '@/models/IKvkExportRun';

@Component({
    components: {SimpleCardDialog, KvkInfo, StatusColorChip,
            AutoLoadingButton, SimpleDeleteDialog, SimpleMessageWrapper},
})
export default class KvkList extends Mixins(ListsObjects, AuthorizationMixin, ShowsMessages) {
    public drawer: boolean = true;
    public showInfoModal: boolean = false;
    public selectedKvkId: number|null = null;
    public selectedKvk: IKvk|null = null;
    public toggleValue: boolean = false;
    public registerOptions: Idable[] = [];
    public selectedRegisters: IRegister[] = [];
    public headers: any[] = [
        {text: 'Vestigings-nummer', value: 'vestigingsnummer'},
        {text: 'Dossiernummer', value: 'dossiernummer'},
        {text: 'Bedrijfsnaam', value: 'bedrijfsnaam'},
        {text: 'Straatnaam', value: 'straatnaam'},
        {text: 'Huisnummer', value: 'huisnummer'},
        {text: 'Postcode', value: 'postcode'},
        {text: 'Woonplaats', value: 'woonplaats'},
        {text: 'Website', value: 'website'},
        {text: 'Status', value: 'status'},
        {text: 'Actie', value: '', sortable: false, searchable: false},
    ];
    @State((state: any) => state.authentication.user)
    public currentUser!: IGebruiker;

    public statusOptions: Array<{ label: string, value: KvkStatus }> = kvkStatusOptions;
    public kvkExportRun: IKvkExportRun | null = null;

    public loading: boolean = false;

    public showExportDialog: boolean = false;

    public hasDatumEindeOptions: Array<{ label: string, value: string }> = bevatDatumEindeOptions;

    public hasWijzeindeOptions: Array<{ label: string, value: string }> = bevatWijzeEindeOptions;

    public get api() {
        return this.$api.kvk;
    }

    public async toKvkManualProcessing() {
        await this.$router.push({name: 'kvk_manual_list'});
    }

    public get rules() {
        return {
            huisnummer: [number],
            dossiernummer: [number],
        };
    }

    public get exportButtonText() {
        if (!this.kvkExportRun) {
            return 'Exporteren';
        } else {
            return this.isExportRunning ? 'Exporteren (Bezig: ' + this.exportProgress + '%)' : 'Exporteren en downloaden';
        }
    }

    public get isExportRunning() {
        return this.kvkExportRun !== null && this.kvkExportRun.status === ExportJobStatus.BUSY;
    }

    public get exportProgress() {
        if (this.kvkExportRun !== null && this.kvkExportRun.status === ExportJobStatus.BUSY) {
            const progress = this.kvkExportRun.processedRecords / this.kvkExportRun.totalRecords;
            return (Math.round(progress * 1000) / 10).toFixed(1);
        } else {
            return null;
        }
    }

    public get existingExportFile() {
        return !this.isExportRunning && this.kvkExportRun && this.kvkExportRun.filename;
    }

    public get dateLastExport() {
        if (this.kvkExportRun && this.kvkExportRun.startedOn) {
            return dateTime(this.kvkExportRun.startedOn);
        } else {
            return 0;
        }
    }

    public get registerLastExport() {
        if (this.kvkExportRun && this.kvkExportRun.registers && this.kvkExportRun.registers.length > 0) {
            return this.kvkExportRun.registers.map((r) => r.naam).join(', ');
        } else {
            return 'Alle registers';
        }
    }

    public get exportDialogText() {
        if (this.isExportRunning) {
            return 'De KvK gegevens worden op de achtergrond geexporteerd naar een bestand. Als dit voltooid is kunnen de gegevens worden gedownload. ';
        } else {
            return 'Je kunt hieronder de kvk gegegevens naar een bestand exporteren en vervolgens het bestand downloaden.\n' +
                'Als er eerder een export is aangemaakt, kun je dit bestand ook meteen downloaden. Deze kan verouderde data bevatten.';
        }
    }

    public async created() {
        await this.loadExportRun();
        this.registerOptions = (await this.$api.register.listAll()).data?.content || [];
        await this.keepLoadingExportUntillDone();
    }

    public async clear() {
        this.searchValue = {};
        this.pagination.sortBy = ['postcode', 'huisnummer'];
        this.pagination.sortDesc = [false, false];
        await this.search();
    }

    @Watch('pagination.sortBy.0', {immediate: true})
    public sortChanged(newValue: string) {
        if (['postcode', 'huisnummer'].includes(newValue)) {
            this.pagination.sortBy = ['postcode', 'huisnummer'];
            const desc = this.pagination.sortDesc[0];
            this.pagination.sortDesc = [desc, desc];
        }
    }

    public async keepLoadingExportUntillDone() {
        if (this.isExportRunning) {
            await this.loadExportRun();
            setTimeout(() => this.keepLoadingExportUntillDone(), 2000);
        }
    }

    public async loadExportRun() {
        this.kvkExportRun = (await this.$api.kvk.exportStatus()).data;
    }

    public statusLabel(value: KvkStatus | null) {
        switch (value) {
            case null:
            case KvkStatus.RVR:
                return 'Relevant';
            case KvkStatus.NRVR:
                return 'Niet relevant';
            case KvkStatus.BEOORDELEN:
                return 'Beoordelen';
        }
    }

    public openInfoModal(id: number) {
        this.showInfoModal = true;
        this.selectedKvkId = id;
    }

    public closeInfoModal() {
        this.showInfoModal = false;
        this.selectedKvkId = null;
    }

    @Watch('selectedKvkId')
    public async loadData() {
        if (!this.selectedKvkId) {
            document.title = 'KvK Overzicht - LISA';
            return;
        }
        this.loadingData = true;
        try {
            const response = await this.$api.kvk.get(this.selectedKvkId!);
            this.selectedKvk = response.data!;
            document.title = 'KvK - ' + this.selectedKvk.bedrijfsnaam + ' - LISA';
        } catch (e) {
            this.showErrorByException(e, 'Er is een fout opgetreden bij het laden van de data.');
        } finally {
            this.loadingData = false;
        }
    }

    public async manualAction(message: null|string) {
        this.closeInfoModal();
        await this.loadListData();
        if (message) {
            this.addMessage(message, -1, false);
        }
    }

    public async exportKvk() {
        this.loading = true;
        const createRequest =  {
            registers: this.selectedRegisters,
        } as unknown as IExportKvkCreate;
        await this.$api.kvk.exportSchaduwTabel(createRequest);
    }

    public downloadKvks() {
        const filename = this.kvkExportRun!.filename;
        location.href = url('static/uploads/' + filename);
    }

    public url(path: string) {
        return url(path);
    }
}
