













































































































































































































































import {ListsObjects} from '@/mixins/ListsObjects';
import {Component, Mixins, Model, Prop, Watch} from 'vue-property-decorator';
import {AuthorizationMixin} from '@/mixins/AuthorizationMixin';
import {SearchCriterion} from '@/utils/api-tools/search-criteria';
import {IKvkUploadRun, KvkStep, KvkUploadStatus} from '@/models/IKvkUploadRun';
import AutoLoadingButton from '@/components/AutoLoadingButton.vue';
import SimpleMessageWrapper from '@/components/SimpleMessageWrapper.vue';
import {Idable} from '@/models/Idable';
import {IKvk, MutatieCategorie} from '@/models/IKvk';
import {IKvkHandmatigCode} from '@/models/constants/IKvkHandmatigCode';
import {IKvkMutatie} from '@/models/IKvkMutatie';
import {zeropad} from '@/utils/filters';

@Component({
    filters: {zeropad},
    components: {
        SimpleMessageWrapper,
        AutoLoadingButton,
    },
})
export default class KvkUploadRunTable extends Mixins(ListsObjects, AuthorizationMixin) {
    public KvkStep = KvkStep;
    public KvkUploadStatus = KvkUploadStatus;
    public searchValue: any = {
        vestigingId: 'eq:',
        manualCompletedAt: 'isNull',
    };

    public drawer: boolean = true;

    @Model('input')
    public refresher!: number;

    @Prop({required: true})
    public kvkUploadRun!: IKvkUploadRun;

    @Prop({required: false, default: false})
    public isManualSelection!: boolean;

    @Prop({required: false, default: false})
    public isCl1!: boolean;

    @Prop({required: false, default: false})
    public isCl2!: boolean;

    @Prop({required: false, default: false})
    public isCl3!: boolean;

    @Prop({required: false, default: false})
    public isCl4!: boolean;

    @Prop({required: false, default: false})
    public isCl5!: boolean;

    @Prop({required: false, default: false})
    public isCl6!: boolean;

    public kvkHandmatigCodeOptions: Idable[] = [];
    public selectedMutationCategory: MutatieCategorie[] =  [];

    public get api() {
        return this.$api.kvkMutatie;
    }

    public get status(): KvkUploadStatus {
        return this.kvkUploadRun.status;
    }

    public get step() {
        return this.kvkUploadRun?.selectedStep;
    }

    public get mutationCategoryOptions() {
        return Object.keys(MutatieCategorie).filter((item) => {
            return isNaN(Number(item));
        });
    }

    public async created() {
        this.kvkHandmatigCodeOptions = (await this.$api.kvkHandmatigCode.listAll()).data?.content || [];
        this.setHeaders();
        this.search();
    }

    @Watch('step')
    public setHeaders() {
        this.headers = [
            {text: 'Dossiernr', value: 'dossiernummer'},
            {text: 'KvK-Vestigingsnr', value: 'vestigingsnummer'},
            {text: 'Bedrijfsnaam', value: 'bedrijfsnaam'},
            {text: 'Straat', value: 'straatnaam'},
            {text: 'Nr', value: 'huisnummer'},
            {text: 'Ltr', value: 'huisletter'},
            {text: 'Toev', value: 'toevoeging'},
            {text: 'Postcode', value: 'postcode'},
            {text: 'Woonplaats', value: 'woonplaats'},
        ];

        if (this.step <= KvkStep.NEW) {
            this.searchValue.manualCompletedAt = null;
        }
        if (this.step >= KvkStep.NEW) {
            this.headers.push({text: 'Mutatie categorie', value: 'mutatieCategorie'});
        }

        if (this.step >= KvkStep.MANUAL_CHECK) {
            if (this.isManualSelection) {
                this.headers.push({text: 'Categorie Handmatig', value: 'kvkHandmatigCodeSet', sortable: false});
            } else if (this.isControlelijst) {
                this.headers.push({text: 'Categorie Handmatig', value: 'kvkHandmatigCodeSet', sortable: false});
                // this.headers.unshift({text: 'Actie', value: 'actie'});
            }
        }
        this.headers.push({text: 'Actie', value: '', sortable: false, searchable: false});
    }

    public get isControlelijst() {
        return this.isCl1 || this.isCl2 || this.isCl3 || this.isCl4 || this.isCl5 || this.isCl6;
    }

    @Watch('totalData')
    public totalDataChanged() {
        this.kvkUploadRun.totalData = this.totalData;
        this.$emit('updateSize');
    }

    @Watch('selectedMutationCategory', { deep: true } )
    public onSelectionMutationCategoryChanged() {
        const key = 'mutatieCategorie:in';
        if (this.selectedMutationCategory!.length !== 0) {
            this.searchValue[key] = this.selectedMutationCategory
                .map((category) => this.mutatieCategory(category))
                .join(',');
        } else {
            this.searchValue[key] = null;
        }
    }

    public get allowDefaultButtons() {
        return !this.isCl1 && !this.isCl2 && !this.isCl3 && !this.isCl4 && !this.isCl5 && !this.isCl6;
    }

    public getCategorieHandmatigList(kvkHandmatigCodes: IKvkHandmatigCode[]) {
        return kvkHandmatigCodes ? kvkHandmatigCodes.map((kvkHandmatigCode) => kvkHandmatigCode.code).join(', ') : '';
    }

    public openInfoModal(kvkOrKvkMutatie: IKvkMutatie) {
        this.$emit('openInfoModal', kvkOrKvkMutatie);
    }

    public mutatieCategory(category: MutatieCategorie) {
        return MutatieCategorie[category];
    }

    @Watch('kvkUploadRun.selectedStep')
    protected async search() {
        const criteria: SearchCriterion[] = [];
        for (const key in this.searchValue) {
            if (this.searchValue.hasOwnProperty(key)) {
                let value = this.searchValue[key];
                if (value != null) {
                    // Strip zero's from the beginning of the string (needed for fields like kvk-vestigingsnummer)
                    if (/^\d+$/.test(value)) {
                        value = parseInt(value, 10);
                    }
                    criteria.push(new SearchCriterion(key, value));
                }
            }
        }
        criteria.push(new SearchCriterion('uploadRunId', '' + this.kvkUploadRun.id));

        switch (this.kvkUploadRun.selectedStep) {
            case KvkStep.UPLOAD:
                // Nothing special; select all mutation-rows
                break;
            case KvkStep.MUTATIONS:
                criteria.push(new SearchCriterion('directMutation', 'true'));
                break;
            case KvkStep.NEW:
                criteria.push(new SearchCriterion('directMutation', 'false'));
                break;
            case KvkStep.MANUAL_CHECK:
                break;
        }

        if (this.isManualSelection) {
            criteria.push(new SearchCriterion('mutatieCategorie:in', '2,3,4'));
            criteria.push(new SearchCriterion('automatischOpgenomen', 'false'));
            criteria.push(new SearchCriterion('directMutation', 'false'));
        }

        if (this.isControlelijst) {
            criteria.push(new SearchCriterion('vestigingId', 'isNotNull'));

            // Note sure if still needed, but at least CL1 should NOT use this filter.
            // criteria.push(new SearchCriterion('automatischOpgenomen', 'false'));
        }
        if (this.isCl6) {
            // criteria.push(new SearchCriterion('oldVestigingData', 'isNotNull'));
            criteria.push(new SearchCriterion('correctielijstType:eq', '6'));
        }
        if (this.isCl5) {
            // criteria.push(new SearchCriterion('oldVestigingData', 'isNotNull'));
            criteria.push(new SearchCriterion('correctielijstType:eq', '5'));
        }
        if (this.isCl4) {
            // criteria.push(new SearchCriterion('oldVestigingData', 'isNotNull'));
            criteria.push(new SearchCriterion('correctielijstType:eq', '4'));
        }
        if (this.isCl3) {
            // criteria.push(new SearchCriterion('oldVestigingData', 'isNotNull'));
            criteria.push(new SearchCriterion('correctielijstType:eq', '3'));
        }
        if (this.isCl2) {
            // criteria.push(new SearchCriterion('oldVestigingData', 'isNotNull'));
            criteria.push(new SearchCriterion('correctielijstType:eq', '2'));
        }
        if (this.isCl1) {
            criteria.push(new SearchCriterion('correctielijstType:eq', '1'));
        }
        this.searchCriteria = criteria;
        this.debouncedLoadData();
    }

}
