
























































































import SimpleDeleteDialog from '@/components/SimpleDeleteDialog.vue';
import SimpleMessageWrapper from '@/components/SimpleMessageWrapper.vue';
import {Component, Mixins} from 'vue-property-decorator';
import AutoLoadingButton from '@/components/AutoLoadingButton.vue';
import {AuthorizationMixin} from '@/mixins/AuthorizationMixin';
import StatusColorChip from '@/components/StatusColorChip.vue';
import {State} from 'vuex-class';
import {IGebruiker} from '@/models/IGebruiker';
import KvkInfo from '@/views/kvk/KvkInfo.vue';
import {IKvkUploadRun, KvkStep, KvkUploadStatus} from '@/models/IKvkUploadRun';
import KvkUploadRunStepper from '@/views/kvk-upload-run/KvkUploadRunStepper.vue';
import {Idable} from '@/models/Idable';
import KvkInfoAlert from '@/views/kvk-upload-run/KvkInfoAlert.vue';
import KvkUploadRunTable from '@/views/kvk-upload-run/KvkUploadRunTable.vue';
import KvkNextButton from '@/views/kvk-upload-run/KvkNextButton.vue';
import KvkMutatieCategorieOverzicht from '@/views/kvk-upload-run/KvkMutatieCategorieOverzicht.vue';
import KvkUploadSummary from '@/views/kvk-upload-run/KvkUploadSummary.vue';
import {ShowsMessages} from '@/mixins/ShowsMessages';
import ManualSelection from '@/views/kvk-upload-run/ManualSelection.vue';
import {IKvkMutatie} from '@/models/IKvkMutatie';

@Component({
        components: {
            ManualSelection,
            KvkUploadSummary,
            KvkMutatieCategorieOverzicht,
            KvkNextButton,
            KvkUploadRunTable,
            KvkInfoAlert,
            KvkUploadRunStepper,
            KvkInfo, StatusColorChip, AutoLoadingButton, SimpleDeleteDialog, SimpleMessageWrapper},
    })
    export default class KvkUploadRunView extends Mixins(ShowsMessages, AuthorizationMixin) {
        public KvkUploadStatus = KvkUploadStatus;

        public showInfoModal: boolean = false;
        public showInvalidCountModal: boolean = false;
        public selectedKvkMutatie: IKvkMutatie|null = null;
        public uploadId: number|string|null = null;
        public kvkUploadRun: IKvkUploadRun | null = null;
        public kvkHandmatigCodeOptions: Idable[] = [];
        public loadingData: boolean = false;
        public refresher: number = 0;

        @State((state: any) => state.authentication.user)
        public currentUser!: IGebruiker;

        public get status() {
            return this.kvkUploadRun!.status;
        }

        public get selectedStep() {
            return this.kvkUploadRun!.selectedStep;
        }

        public setStepByStatus() {
            switch (this.status) {
                case KvkUploadStatus.REVERTED:
                case KvkUploadStatus.UPLOAD_IN_PROGRESS:
                case KvkUploadStatus.UPLOAD_DONE:
                case KvkUploadStatus.CONFIRM_UPLOAD_IN_PROGRESS:
                case KvkUploadStatus.CONFIRM_UPLOADD_FAILED:
                    this.kvkUploadRun!.selectedStep = KvkStep.UPLOAD;
                    break;
                case KvkUploadStatus.CONFIRM_UPLOAD_DONE:
                case KvkUploadStatus.MUTATIONS_IN_PROGRESS:
                case KvkUploadStatus.MUTATIONS_DONE:
                case KvkUploadStatus.MUTATIONS_FAILED:
                    this.kvkUploadRun!.selectedStep = KvkStep.MUTATIONS;
                    break;
                case KvkUploadStatus.NEW_LINK_IN_PROGRESS:
                case KvkUploadStatus.NEW_LINK_DONE:
                case KvkUploadStatus.AUTO_NEW_PREPARE_IN_PROGRESS:
                case KvkUploadStatus.AUTO_NEW_PREPARE_DONE:
                case KvkUploadStatus.AUTO_NEW_IN_PROGRESS:
                case KvkUploadStatus.NEW_LINK_FAILED:
                    this.kvkUploadRun!.selectedStep = KvkStep.NEW;
                    break;
                case KvkUploadStatus.AUTO_NEW_DONE:
                case KvkUploadStatus.MANUAL_SELECTION:
                case KvkUploadStatus.MANUAL_DONE:
                    this.kvkUploadRun!.selectedStep = KvkStep.MANUAL_CHECK;
                    break;

            }
        }

        public get isManualStep() {
            return this.kvkUploadRun!.selectedStep === KvkStep.MANUAL_CHECK;
        }

        public get isFinished() {
            return this.kvkUploadRun!.status === KvkUploadStatus.MANUAL_DONE;
        }

        public get currentRunProgress() {
            return this.kvkUploadRun!.currentRunProgress;
        }

        public get hasInvalidRsi() {
            return this.currentRunProgress!.invalidCount && this.currentRunProgress!.invalidCount > 0;
        }

        public get api() {
            return this.$api.kvkMutatie;
        }

        public async created() {
            this.uploadId = this.$route.params.id;
            const uploadRun = (await this.$api.kvkUploadRun.get(this.uploadId)).data!;
            uploadRun.selectedStep = KvkStep.UPLOAD;
            uploadRun.totalData = 0;
            this.kvkUploadRun = uploadRun;
            this.setStepByStatus();
            this.kvkHandmatigCodeOptions = (await this.$api.kvkHandmatigCode.listAll()).data?.content || [];
        }

        public openInfoModal(kvkMutatie: IKvkMutatie) {
            this.showInfoModal = true;
            this.selectedKvkMutatie = kvkMutatie;
        }

        public async reloadUploadRun() {
            const kvkUploadRunId = this.$route.params.id;
            this.kvkUploadRun = (await this.$api.kvkUploadRun.get(kvkUploadRunId)).data!;
            this.setStepByStatus();
        }

        public manualAction() {
            this.refresher++;
            this.updateKvkUploadRun();
        }

        public async updateKvkUploadRun() {
            if (this.uploadId && this.kvkUploadRun) {
                const uploadRunPersisted = (await this.$api.kvkUploadRun.get(this.uploadId)).data!;
                this.kvkUploadRun.clRowsProcessed = uploadRunPersisted.clRowsProcessed;
                this.kvkUploadRun.manualRowsProcessed = uploadRunPersisted.manualRowsProcessed;
            }
        }

        public async abortStatus() {
            if (this.uploadId) {
                await this.$api.kvkUploadRun.abortStatus(+this.uploadId);
                setTimeout(() => {
                    window.location.reload();
                }, 500);
            }
        }

        public async advanceStatus() {
            if (this.loadingData) {
                console.warn('Cannot advanceStatus; still loading data');
                return;
            }
            if (this.uploadId) {
                switch (this.status) {
                    case KvkUploadStatus.UPLOAD_DONE:
                        this.showMessage('De KvK gegevens worden vastgelegd.');
                        break;
                    case KvkUploadStatus.CONFIRM_UPLOADD_FAILED:
                        this.showMessage('De KvK gegevens die nog niet zijn goedgekeurd worden opnieuw verwerkt.');
                        break;
                    case KvkUploadStatus.CONFIRM_UPLOAD_DONE:
                        this.showMessage('Gegevens bevestigd. Je wordt doorgestuurd naar de volgende stap.');
                        break;
                    case KvkUploadStatus.MUTATIONS_DONE:
                        this.showMessage('De mutaties zijn bevestigd. Je wordt doorgestuurd naar de volgende stap.');
                        break;
                    case KvkUploadStatus.MUTATIONS_FAILED:
                        this.showMessage('De KvK mutaties die nog niet zijn verwerkt worden opnieuw verwerkt.');
                        break;
                    case KvkUploadStatus.NEW_LINK_DONE:
                        this.showMessage('De nieuwe koppelingen zijn bevestigd.');
                        break;
                    case KvkUploadStatus.NEW_LINK_FAILED:
                        this.showMessage('De KvK nieuwe inschrijvingen die nog niet zijn verwerkt worden opnieuw verwerkt.');
                        break;
                    case KvkUploadStatus.AUTO_NEW_PREPARE_DONE:
                        this.showMessage('De inschrijvingen worden verwerkt. Je wordt doorgestuurd naar de volgende stap.');
                        break;
                    case KvkUploadStatus.AUTO_NEW_DONE:
                        this.showMessage('De verwerkte inschrijvingen zijn bevestigd. Je wordt doorgestuurd naar de volgende stap.');
                        break;
                    case KvkUploadStatus.MANUAL_SELECTION:
                        this.showMessage('Handmatige verwerking voltooid');
                        break;
                    case KvkUploadStatus.MANUAL_DONE:
                        this.showMessage('Dit KvK-mutatiebestand is voltooid.');
                        break;
                    default:
                        return; // when status is any of the 'in_progress' statuses, then advance is not allowed.
                }
                this.loadingData = true;
                await this.$api.kvkUploadRun.advanceStatus(+this.uploadId);
                setTimeout(() => {
                    window.location.reload();
                }, 500);
                this.loadingData = false;
            }
        }

        public get invalidInfo() {
            if (!this.kvkUploadRun) {
                return [];
            }
            return Object.entries(this.currentRunProgress!.invalidInfo);
        }
    }
