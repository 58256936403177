




























































































































































































































































































































































































































    import {IGebruiker} from '@/models/IGebruiker';
    import {Component, Mixins} from 'vue-property-decorator';
    import {Action, State} from 'vuex-class';
    import {AuthorizationMixin} from '@/mixins/AuthorizationMixin';
    import { url } from '@/utils/http';
    import MfaEnableAlert from '@/components/MfaEnableAlert.vue';
    import settings from '@/utils/settings';

    @Component({
        components: {MfaEnableAlert},
    })
    export default class AppBase extends Mixins(AuthorizationMixin) {
        public settings: any = settings;
        public drawer: boolean = true;

        @State((state: any) => state.routing.publicName)
        public publicName!: string;

        @State((state: any) => state.authentication.user)
        public currentUser!: IGebruiker;

        @Action('logout')
        public logoutAction!: () => Promise<void>;

        get openVariableSubMenu() {
            return this.$route.meta.isConstantRoute || false;
        }

        // get breadcrumbs() {
        //     return [
        //         {
        //             text: 'Dashboard',
        //             disabled: false,
        //             href: 'breadcrumbs_dashboard',
        //         },
        //         {
        //             text: 'Link 1',
        //             disabled: false,
        //             href: 'breadcrumbs_link_1',
        //         },
        //         {
        //             text: 'Link 2',
        //             disabled: true,
        //             href: 'breadcrumbs_link_2',
        //         },
        //     ];
        // }

        public url(path: string) {
            return url(path);
        }

        public async logout() {
            await this.logoutAction();
            await this.$router.push('/login');
    }
}
