import {Idable} from '@/models/Idable';
import {IRegister} from '@/models/IRegister';

export interface IKvkUploadRun extends Idable {
    datumMutaties: number|null;
    jaar: number|null;
    maand: number|null;
    file: any|null;
    register: IRegister|null;

    filename: string;

    manualRows: number|null;
    manualRowsProcessed: number|null;

    clRows: number|null;
    clRowsProcessed: number|null;
    totalRows: number|null;
    newRows: number|null;
    mutationRowsCompleted: number|null;
    newRowsCompleted: number|null;

    status: KvkUploadStatus;

    mutatieSoortCountInfo: Map<string, number>;
    existingKvkMutatieSoortCountInfo: Map<string, number>;
    runProgressMap: {
        upload: IRunProgress,
        confirm: IRunProgress,
        mutate: IRunProgress,
        link: IRunProgress,
        manual_check: IRunProgress,
    };
    currentRunProgress: IRunProgress | null;

    // non-server side; extra helper-properties
    selectedStep: KvkStep;
    totalData: number;

    failureReason: string | null;
}

export enum KvkStep {
    UPLOAD = 1,
    MUTATIONS = 2,
    NEW = 3,
    MANUAL_CHECK = 4,
}

export enum KvkUploadStatus {
    FAKE = -99,
    REVERTED = -1,

    UPLOAD_IN_PROGRESS = 10,
    UPLOAD_DONE = 15,
    CONFIRM_UPLOAD_IN_PROGRESS = 20,
    CONFIRM_UPLOADD_FAILED= 21,
    CONFIRM_UPLOAD_DONE = 25,
    MUTATIONS_IN_PROGRESS = 30,
    MUTATIONS_FAILED = 31,
    MUTATIONS_DONE = 35,
    NEW_LINK_IN_PROGRESS = 40,
    NEW_LINK_FAILED = 41,
    NEW_LINK_DONE = 45,
    AUTO_NEW_PREPARE_IN_PROGRESS = 50,
    AUTO_NEW_PREPARE_DONE = 55,
    AUTO_NEW_IN_PROGRESS = 60,
    AUTO_NEW_DONE = 65,
    MANUAL_SELECTION = 70,
    MANUAL_PROCESSING = 75,
    MANUAL_DONE = 80,
}

export interface IRunProgress extends Idable {
    startedOn: string;
    stoppedOn: string;
    canceledOn: string;
    processedCount: number;
    failedCount: number;
    skippedCount: number;
    invalidCount: number;
    invalidInfo: Map<number, string>;
    totalCount: number;
}

export function buildEmptyUploadRun(): IKvkUploadRun {
    return {
        id: '',
        datumNrvr: new Date().getTime(),
        datumMutaties: new Date().getTime(),
        jaar: new Date().getFullYear(),
        maand: new Date().getMonth() + 1,
        register: null,
        filename: null,
        status: null,
        mutatieSoortCountInfo: null,
        existingKvkMutatieSoortCountInfo: null,
        runProgressMap: null,
        currentRunProgress: null,
    } as unknown as IKvkUploadRun;
}

export const monthOptions: Array<{ label: string, value: number }> = [
    {label: 'Januari', value: 1},
    {label: 'Februari', value: 2},
    {label: 'Maart', value: 3},
    {label: 'April', value: 4},
    {label: 'Mei', value: 5},
    {label: 'Juni', value: 6},
    {label: 'Juli', value: 7},
    {label: 'Augustus', value: 8},
    {label: 'September', value: 9},
    {label: 'Oktober', value: 10},
    {label: 'November', value: 11},
    {label: 'December', value: 12},
];

