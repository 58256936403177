var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-5",attrs:{"fluid":""}},[_c('kvk-signal-services'),_c('div',{staticClass:"d-flex"},[_c('v-spacer'),_c('v-switch',{attrs:{"inset":"","input-value":"true","label":"Basis signalen"},on:{"change":function () { this$1.$router.push({name: 'kvk_signal_vestiging_list'}) }}})],1),_c('v-layout',{attrs:{"column":""}},[_c('v-data-table',{staticClass:"elevation-1 mb-5 row-pointer",attrs:{"headers":_vm.headers,"options":_vm.pagination,"items":_vm.objects,"footer-props":_vm.pagination.footerProps,"server-items-length":_vm.totalData,"loading":_vm.loadingData},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',{on:{"click":function($event){return _vm.toEdit(item.object)}}},[_c('td',[_vm._v(" "+_vm._s(item.object.id || '-')+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm._f("dateTime")(item.object.timestamp))+" ")]),_c('td',[_vm._v(" "+_vm._s(item.object.kvkNummer || '-')+" ")]),_c('td',[_vm._v(" "+_vm._s(item.object.vestigingsnummers ? item.object.vestigingsnummers.join(', ') : '')+" ")]),_c('td',[_vm._v(" "+_vm._s(item.object.detailsAttempts || '-')+" ")]),_c('td',[_vm._v(" "+_vm._s(item.object.detailsSuccess ? 'Ja' : 'Nee')+" ")]),_c('td',[_vm._v(" "+_vm._s(item.object.failureReason || '-')+" ")])])]}}])}),_c('v-navigation-drawer',{attrs:{"fixed":"","app":"","clipped":"","right":"","permanent":""}},[_c('form',{staticClass:"pa-3",on:{"submit":function($event){$event.preventDefault();}}},[_c('h3',[_vm._v(" Zoeken ")]),_c('v-text-field',{attrs:{"label":"ID","hide-details":""},model:{value:(_vm.searchValue['id:eq']),callback:function ($$v) {_vm.$set(_vm.searchValue, 'id:eq', $$v)},expression:"searchValue['id:eq']"}}),_c('v-text-field',{attrs:{"label":"Dossiernummer","hide-details":""},model:{value:(_vm.searchValue['kvkNummer:eq']),callback:function ($$v) {_vm.$set(_vm.searchValue, 'kvkNummer:eq', $$v)},expression:"searchValue['kvkNummer:eq']"}}),_c('v-select',{attrs:{"items":[
                            {text: 'Ja', value: '1'},
                            {text: 'Nee', value: '0'},
                            {text: 'Alle', value: null} ],"label":"Details opgehaald","hide-details":""},model:{value:(_vm.searchValue['detailsSuccess:eq']),callback:function ($$v) {_vm.$set(_vm.searchValue, 'detailsSuccess:eq', $$v)},expression:"searchValue['detailsSuccess:eq']"}}),_c('auto-loading-button',{staticClass:"mt-4 mr-2",attrs:{"type":"submit","color":"primary"},on:{"click":_vm.search}},[_vm._v(" Zoeken ")]),_c('v-btn',{staticClass:"mr-2 mt-4",attrs:{"type":"reset"},on:{"click":_vm.clear}},[_vm._v(" Wissen ")])],1)])],1),_c('SimpleMessageWrapper',{attrs:{"messageData":_vm.messages}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }