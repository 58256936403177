












































































































import {Component, Mixins} from 'vue-property-decorator';
import StatusColorChip from '@/components/StatusColorChip.vue';
import AutoLoadingButton from '@/components/AutoLoadingButton.vue';
import SimpleDeleteDialog from '@/components/SimpleDeleteDialog.vue';
import SimpleMessageWrapper from '@/components/SimpleMessageWrapper.vue';
import {ListsObjects} from '@/mixins/ListsObjects';
import {AuthorizationMixin} from '@/mixins/AuthorizationMixin';
import {State} from 'vuex-class';
import {IGebruiker} from '@/models/IGebruiker';
import {date, dateTime} from '@/utils/filters';
import {IKvkSignalServicesStatusInfo} from '@/models/IKvkSignalServicesStatusInfo';
import KvkSignalServices from '@/views/kvk-signal/KvkSignalServices.vue';

@Component({
    filters: {date, dateTime},
    components: {KvkSignalServices, StatusColorChip, AutoLoadingButton, SimpleDeleteDialog, SimpleMessageWrapper},
})
export default class KvkSignalVestigingList extends Mixins(ListsObjects, AuthorizationMixin) {
    public headers: any[] = [
        {text: 'ID', value: 'id'},
        {text: 'Datum', value: 'date'},
        {text: 'Dossiernummer', value: 'dossiernummer'},
        {text: 'Vestigingnummer', value: 'vestigingnummer'},
        {text: 'Pogingen vestiging-API', value: 'fetchVestigingAttempts'},
        {text: 'Gelukt vestiging-API', value: 'fetchVestigingSuccess'},
        {text: 'Reden indien mislukt', value: 'failureReason'},
    ];

    public statusInfo: IKvkSignalServicesStatusInfo | null = null;

    @State((state: any) => state.authentication.user)
    public currentUser!: IGebruiker;

    public get api() {
        return this.$api.kvkSignalVestiging;
    }

    public toEdit(object: string|number|any) {
        this.$router.push({name: 'kvk_signal_vestiging_view', params: { id: object.id.toString() }});
    }

    public getDateTimeFromTimestamp(timestamp: number): string {
        return new Date(timestamp * 1000).toLocaleString();
    }
}
