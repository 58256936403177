









































































import SimpleDeleteDialog from '@/components/SimpleDeleteDialog.vue';
import SimpleMessageWrapper from '@/components/SimpleMessageWrapper.vue';
import {ListsObjects} from '@/mixins/ListsObjects';
import {Component, Mixins} from 'vue-property-decorator';
import AutoLoadingButton from '@/components/AutoLoadingButton.vue';
import {AuthorizationMixin} from '@/mixins/AuthorizationMixin';
import {dateTime, numberToMonth, date, timeDiff, uploadStatusText} from '@/utils/filters';
import SimpleConfirmDialog from '@/components/SimpleConfirmDialog.vue';
import {IKvkUploadRun, KvkUploadStatus} from '@/models/IKvkUploadRun';
import {IRegister} from '@/models/IRegister';
import {Pagination} from '@/utils/api-tools/pagination';

@Component({
        filters: {
            timeDiff,
            dateTime,
            date,
            uploadStatusText,
            numberToMonth,
        },
        components: {SimpleConfirmDialog, AutoLoadingButton, SimpleDeleteDialog, SimpleMessageWrapper},
    })
    export default class KvkUploadRunList extends Mixins(ListsObjects, AuthorizationMixin) {
        public headers: any[] = [
            {text: 'ID', value: 'id'},
            {text: 'Bestandsnaam', value: 'filename'},
            {text: 'Totaal rijen', value: 'totalRows', sortable: false},
            {text: 'Handmatig verwerkt', value: 'manualRowsProcessed', sortable: false},
            {text: 'Correctielijst verwerkt', value: 'clRowProcessed', sortable: false},
            {text: 'Datum KvK', value: 'datumMutaties'},
            {text: 'Status', value: 'status'},
            {text: 'Aangemaakt op', value: 'toegevoegdOp'},
            {text: '', value: 'action', sortable: false, searchable: false},
        ];
        public loading = false;

        public get api() {
            return this.$api.kvkUploadRun;
        }

        public manualProgress(uploadRun: IKvkUploadRun) {
            if (uploadRun.status === KvkUploadStatus.MANUAL_PROCESSING
                || uploadRun.status === KvkUploadStatus.MANUAL_DONE
                || uploadRun.status === KvkUploadStatus.MANUAL_SELECTION
                || (uploadRun.manualRowsProcessed !== null && uploadRun.manualRows !== null)
            ) {
                return uploadRun.manualRowsProcessed + '/' + uploadRun.manualRows;
            }
            return '-';
        }

        public clProgress(uploadRun: IKvkUploadRun) {
            if (uploadRun.status === KvkUploadStatus.MANUAL_PROCESSING
                || uploadRun.status === KvkUploadStatus.MANUAL_DONE
                || uploadRun.status === KvkUploadStatus.MANUAL_SELECTION
                || (uploadRun.clRowsProcessed !== null && uploadRun.clRows !== null)
            ) {
                return uploadRun.clRowsProcessed + '/' + uploadRun.clRows;
            }
            return '-';
        }
    }
