






























































import {Component, Prop, Vue} from 'vue-property-decorator';
import {IKvkUploadRun, KvkStep, KvkUploadStatus} from '@/models/IKvkUploadRun';

@Component({
})
export default class KvkInfoAlert extends Vue {
    public KvkStep = KvkStep;
    public KvkUploadStatus = KvkUploadStatus;

    @Prop({required: true})
    public kvkUploadRun!: IKvkUploadRun | null;

    public get status() {
        return this.kvkUploadRun?.status;
    }

    public get step() {
        return this.kvkUploadRun?.selectedStep;
    }
}
