





















import {Component, Prop, Vue} from 'vue-property-decorator';
import {IFilter} from '@/models/IFilter';
import {IImportColumnTemplate} from '@/models/IImportColumnTemplate';
import {filterTypeText} from '@/utils/filters';
import {IExportTemplateInput} from '@/models/IExportTemplateInput';
import TemplateInput from '@/views/exports/components/TemplateInput.vue';

@Component({
    filters: {filterTypeText},
    components: {TemplateInput},
    name: 'template-row',
})
export default class TemplateRow extends Vue {
    @Prop()
    public filter!: IFilter;
    @Prop({default: null})
    public parentFilter!: IFilter | null;

    @Prop()
    public templateInputs!: IExportTemplateInput[];

    public searchableFields: string[] = [];

    public operators: Array<{ label: string, value: string, noInput?: boolean }> = [
        {label: 'Gelijk aan (=)', value: 'eq'},
        {label: 'Niet gelijk aan (!=)', value: 'ne'},
        {label: 'Bevat', value: 'contains'},
        {label: 'Groter dan (>)', value: 'gt'},
        {label: 'Groter dan of gelijk aan (>=)', value: 'goe'},
        {label: 'Minder dan (<)', value: 'lt'},
        {label: 'Minder dan of gelijk aan (<=)', value: 'loe'},
        {label: 'Is leeg', value: 'isEmpty' },
        {label: 'Is niet leeg', value: 'isNotEmpty'},
        {label: 'Is lege verzameling', value: 'isEmptySet'},
        {label: 'In', value: 'in'},
        {label: 'Begint met', value: 'sw'},
    ];

    public get isRoot() {
        return !this.parentFilter;
    }

    public get rules() {
        return {
            field: [],
            operator: [],
            value: [],
        };
    }

    public async created() {
        this.searchableFields = (await this.$api.export.searchableFields()).data! || [];
    }

    public hasInputName(childFilter: IFilter) {
        return !(childFilter.inputName === '' || !childFilter.inputName);
    }

    public getOptionsFor(field: string) {
        return this.templateInputs.find((t: any) => t.fieldName === field)?.options;
    }

    public getTypeFor(field: string) {
        return this.templateInputs.find((t: any) => t.fieldName === field)?.fieldType;
    }

    public removeFilter(columnTemplate: IImportColumnTemplate) {
        if (this.filter) {
            const columnTemplateIdx = this.filter.filters.findIndex((t: any) => t === columnTemplate);
            if (columnTemplateIdx >= 0) {
                this.filter.filters.splice(columnTemplateIdx, 1);
            }
        }
    }

    public getInputType(field: string, operator: string) {
        if (['isNull', 'isNotNull', 'isEmptySet'].includes(operator)) {
            return null;
        }
        return 'text';
    }
}
