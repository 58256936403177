










































































































































































































import SimpleDeleteDialog from '@/components/SimpleDeleteDialog.vue';
import SimpleMessageWrapper from '@/components/SimpleMessageWrapper.vue';
import {ListsObjects} from '@/mixins/ListsObjects';
import {Component, Mixins, Prop} from 'vue-property-decorator';
import AutoLoadingButton from '@/components/AutoLoadingButton.vue';
import {AuthorizationMixin} from '@/mixins/AuthorizationMixin';
import {dateTime, exportStatusText, numberFormat, timeDiff} from '@/utils/filters';
import {url} from '@/utils/http';
import {IExportRunCreate} from '@/models/IExportRunCreate';
import {SearchCriterion} from '@/utils/api-tools/search-criteria';
import {IGebruiker} from '@/models/IGebruiker';

@Component({
    methods: {exportStatusText},
    filters: {
        exportStatusText,
        dateTime,
        timeDiff,
        numberFormat,
    },
    components: {AutoLoadingButton, SimpleDeleteDialog, SimpleMessageWrapper},
})
export default class ExportRunList extends Mixins(ListsObjects, AuthorizationMixin) {
    @Prop({default: false})
    public showTemplates!: boolean;

    public cancelAllDialog: boolean = false;

    public drawer: boolean = true;
    public headers: any[] = [
        {text: 'ID', value: 'id'},
        {text: 'Beschrijving', value: 'description'},
        {text: 'Gestart', value: 'startedOn'},
        {text: 'Gestopt', value: 'stoppedOn'},
        {text: 'Aantal rijen', value: 'totalRecords'},
        {text: 'Status', value: 'status'},
        {text: 'Gebruikersnaam', value: 'toegevoegdDoor'},
        {text: '', value: 'action', sortable: false, searchable: false},
    ];

    public exportTemplates: IExportRunCreate[] = [];

    public users: IGebruiker[] = [];

    public async created() {
        this.searchCriteria?.push(new SearchCriterion('isTemplate', this.showTemplates ? 'true' : 'false'));
        this.pagination.page = 1;
        this.debouncedLoadData();
        this.exportTemplates = (await this.$api.export.getExportTemplates()).data || [];
        if (this.isAdmin) {
            this.users = (await this.$api.user.listAll()).data?.content || [];
        }
    }

    public get templates() {
        return this.exportTemplates;
    }

    public get api() {
        return this.$api.export;
    }

    public get hasAccess() {
        return this.isRoleDatabeheer || this.isRoleAnalist;
    }

    public canUpdateExportRun(item: any) {
        return this.isAdmin ||
            ((this.isRoleDatabeheer || this.isRoleAnalist) && this.userHasId(item.toegevoegdDoor.id));
    }

    public getUsername(item: any) {
        if (this.currentUser.id === item.toegevoegdDoor?.id) {
            return this.currentUser.gebruikersnaam;
        } else if (this.isAdmin) {
            return this.users.find((user) => user.id === item.toegevoegdDoor?.id)?.gebruikersnaam ?? '-';
        } else {
            return '-';
        }
    }

    public url(path: string) {
        return url(path);
    }

    public openFromTemplate(templateId: number | string) {
        this.$router.push({name: 'exportCreateTemplate', params: {id: templateId as string}});
    }

    public getPercentage(item: any) {
        if (!item) {
            return '';
        }
        const percentage = item.processedRecords / item.totalRecords * 100;
        if (isNaN(percentage)) {
            return '';
        }
        return '(' + (Math.round(percentage * 10) / 10) + '%)';
    }

    public getDownloadRoute(id: number | string) {
        return {
            name: this.$route.meta.indexRouteName + 'Edit',
            params: {id},
        };
    }

    public async cancelAll() {
        await this.api.cancelAll();
        this.debouncedLoadData();
        this.cancelAllDialog = false;
    }
}
