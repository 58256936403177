













































































































import {Component, Mixins} from 'vue-property-decorator';
import StatusColorChip from '@/components/StatusColorChip.vue';
import AutoLoadingButton from '@/components/AutoLoadingButton.vue';
import SimpleDeleteDialog from '@/components/SimpleDeleteDialog.vue';
import SimpleMessageWrapper from '@/components/SimpleMessageWrapper.vue';
import {ListsObjects} from '@/mixins/ListsObjects';
import {AuthorizationMixin} from '@/mixins/AuthorizationMixin';
import {State} from 'vuex-class';
import {IGebruiker} from '@/models/IGebruiker';
import {date, dateTime} from '@/utils/filters';
import {IKvkSignalServicesStatusInfo} from '@/models/IKvkSignalServicesStatusInfo';

@Component({
    filters: {date, dateTime},
    components: {StatusColorChip, AutoLoadingButton, SimpleDeleteDialog, SimpleMessageWrapper},
})
export default class KvkSignalList extends Mixins(ListsObjects, AuthorizationMixin) {
    public headers: any[] = [
        {text: 'ID', value: 'id'},
        {text: 'Timestamp', value: 'timestamp'},
        {text: 'KvK-nummer', value: 'kvkNummer'},
        {text: 'Vestigingnummer(s)', value: 'vestigingnummers', sortable: false},
        {text: 'Pogingen details', value: 'detailsAttempts'},
        {text: 'Gelukt details', value: 'detailsSuccess'},
        {text: 'Pogingen vestigingen', value: 'vestigingenAttempts'},
        {text: 'Gelukt vestigingen', value: 'vestigingenSuccess'},
        {text: 'Reden indien mislukt', value: 'failureReason'},
    ];

    public statusInfo: IKvkSignalServicesStatusInfo | null = null;

    @State((state: any) => state.authentication.user)
    public currentUser!: IGebruiker;

    public get api() {
        return this.$api.kvkSignal;
    }

    public async created() {
        await this.loadServiceInfo();
    }

    public async loadServiceInfo() {
        this.statusInfo = (await this.api.getServicesStatusInfo()).data;
        if (this.statusInfo?.isRunning) {
            this.loadServiceInfoAfterTimeout();
        }
    }

    public loadServiceInfoAfterTimeout() {
        setTimeout(() => {
            this.loadServiceInfo();
        }, 1500);
    }

    public fetchLists() {
        this.api.fetchLists();
        this.loadServiceInfoAfterTimeout();
    }

    public fetchDetails() {
        this.api.fetchDetails();
        this.loadServiceInfoAfterTimeout();
    }

    public fetchVestigingen() {
        this.api.fetchVestigingen();
        this.loadServiceInfoAfterTimeout();
    }

    public createCma() {
        this.api.createCma();
        this.loadServiceInfoAfterTimeout();
    }

    public abortService() {
        this.api.abortService();
        this.loadServiceInfoAfterTimeout();
    }

    public async startKvkUploadRun() {
        if (this.statusInfo?.lastCmaCreatedTimestamp) {
            await this.api.startKvkUploadRun(this.statusInfo.lastCmaCreatedTimestamp);
            this.addMessage('Mutatieverwerking aangemaakt indien er niet al één bestond tot aan dezelfde datum. Zie overzicht mutatieverwerking om te controleren.');
            this.loadServiceInfoAfterTimeout();
        }
    }

    public toEdit(object: string|number|any) {
        this.$router.push({name: 'kvk_signal_view', params: { id: object.id.toString() }});
    }

    public getDateTimeFromTimestamp(timestamp: number): string {
        return new Date(timestamp * 1000).toLocaleString();
    }
}
