var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.statusInfo)?_c('v-card',[_c('v-card-title',{staticClass:"grey lighten-2 py-0"},[_vm._v(" Status van KvK signaal services ")]),_c('v-card-text',{staticClass:"pt-3 pb-4 mb-2"},[(!_vm.statusInfo.isRunning)?_c('div',{staticClass:"font-weight-bold"},[_vm._v(" Er is momenteel geen service actief ")]):_c('table',[_c('tr',[_c('td',{staticStyle:{"width":"200px"}},[_vm._v(" Actieve service "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var props = ref.props;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""}},'v-icon',props,false),on),[_vm._v("mdi-information")])]}}],null,false,846531719)},[_c('span',[_vm._v("De service die op dit moment aan het draaien is. Er kan maar één service tegelijkertijd draaien.")])])],1),_c('td',[(_vm.statusInfo.runningFetchSignalList)?_c('span',[_vm._v(" stap 1 - Ophalen van lijsten met Signalen ")]):(_vm.statusInfo.runningFetchDetails)?_c('span',[_vm._v(" stap 2 - Ophalen van signaal details ")]):(_vm.statusInfo.runningFetchVestigingen)?_c('span',[_vm._v(" stap 3 - Ophalen van vestigingen per signaal ")]):(_vm.statusInfo.runningCmaCreation)?_c('span',[_vm._v(" stap 4 - Aanmaken van CMA & Kvk-mutatie objecten ")]):_vm._e()])]),_c('tr',[_c('td',[_vm._v(" Gestart op ")]),_c('td',[_vm._v(_vm._s(_vm._f("dateTime")(_vm.statusInfo.startedAt)))])]),_c('tr',[_c('td',[_vm._v(" Request limiet "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var props = ref.props;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""}},'v-icon',props,false),on),[_vm._v("mdi-information")])]}}],null,false,846531719)},[_c('span',[_vm._v(" Het maximale aantal requests tijdens deze service. Deze restrictie is om te voorkomen dat oneindig API requests naar KvK gedaan worden als er verkeerd mis zou gaan. ")])])],1),_c('td',[_vm._v(_vm._s(_vm.statusInfo.runningCmaCreation ? 'n.v.t.' : _vm.statusInfo.maxRequestCount))])]),_c('tr',[_c('td',[_vm._v(" Taken in wachtrij "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var props = ref.props;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""}},'v-icon',props,false),on),[_vm._v("mdi-information")])]}}],null,false,846531719)},[_c('span',[_vm._v(" Het aantal taken dat tijdens deze service wordt uitgevoerd. NB: dit aantal kan oplopen op het moment dat de taken nog ingeladen worden. ")])])],1),_c('td',[_vm._v(_vm._s(_vm.statusInfo.numberOfTasks))])]),_c('tr',[_c('td',[_vm._v(" Taken uitgevoerd "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var props = ref.props;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""}},'v-icon',props,false),on),[_vm._v("mdi-information")])]}}],null,false,846531719)},[_c('span',[_vm._v(" Het aantal afgeronde taken tijdens uitvoering van deze service. ")])])],1),_c('td',[_vm._v(_vm._s(_vm.statusInfo.numberOfTasksDone))])])]),_c('br'),_c('v-btn',{staticClass:"mb-2 mr-2",attrs:{"disabled":_vm.statusInfo.isRunning,"small":""},on:{"click":_vm.fetchLists}},[_vm._v("Start stap 1: signaal lijsten ophalen ")]),_c('v-btn',{staticClass:"mb-2 mr-2",attrs:{"disabled":_vm.statusInfo.isRunning,"small":""},on:{"click":_vm.fetchDetails}},[_vm._v("Start stap 2: details ophalen ")]),_c('v-btn',{staticClass:"mb-2 mr-2",attrs:{"disabled":_vm.statusInfo.isRunning,"small":""},on:{"click":_vm.fetchVestigingen}},[_vm._v("Start stap 3: vestigingen ophalen ")]),_c('v-btn',{staticClass:"mb-2 mr-2",attrs:{"disabled":_vm.statusInfo.isRunning,"small":""},on:{"click":_vm.createCma}},[_vm._v("Start stap 4: CMA objecten aanmaken ")]),_c('br'),_c('v-btn',{staticClass:"mb-2 mr-2",attrs:{"color":"error","disabled":!_vm.statusInfo.isRunning,"small":""},on:{"click":_vm.abortService}},[_vm._v("Service afbreken ")]),(_vm.statusInfo.lastCmaCreatedTimestamp)?_c('div',{staticClass:"mt-4"},[_vm._v(" CMA objecten zijn gemaakt tot aan: "+_vm._s(_vm._f("date")(_vm.statusInfo.lastCmaCreatedTimestamp))+" "),_c('br'),_c('v-btn',{staticClass:"mt-3",attrs:{"color":"primary","small":""},on:{"click":_vm.startKvkUploadRun}},[_vm._v(" Mutatieverwerking aanmaken ")])],1):_vm._e()],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }