









































import {Component, Prop, Vue} from 'vue-property-decorator';
import {IKvkUploadRun, KvkStep, KvkUploadStatus} from '@/models/IKvkUploadRun';

@Component({
})
export default class KvkNextButton extends Vue {
    public KvkStep = KvkStep;
    public KvkUploadStatus = KvkUploadStatus;

    public confirmRevertDialog = false;
    public loadingRevert = false;

    public get step() {
        return this.kvkUploadRun?.selectedStep;
    }

    @Prop({required: true})
    public kvkUploadRun!: IKvkUploadRun | null;

    public get status() {
        return this.kvkUploadRun!.status;
    }

    public get inProgress() {
        return this.status === KvkUploadStatus.CONFIRM_UPLOAD_IN_PROGRESS ||
                this.status === KvkUploadStatus.MUTATIONS_IN_PROGRESS ||
                this.status === KvkUploadStatus.NEW_LINK_IN_PROGRESS;
    }

    public get advanceStatusDisabled() {
        switch (this.status) {
            case KvkUploadStatus.CONFIRM_UPLOAD_IN_PROGRESS:
            case KvkUploadStatus.MUTATIONS_IN_PROGRESS:
            case KvkUploadStatus.NEW_LINK_IN_PROGRESS:
                return true; // TODO: allow retry certain time has passed (server may have been restarted)
            case KvkUploadStatus.UPLOAD_DONE:
            case KvkUploadStatus.CONFIRM_UPLOAD_DONE:
            case KvkUploadStatus.MUTATIONS_DONE:
            case KvkUploadStatus.AUTO_NEW_PREPARE_DONE:
            case KvkUploadStatus.AUTO_NEW_DONE:
            case KvkUploadStatus.NEW_LINK_DONE:
            case KvkUploadStatus.CONFIRM_UPLOADD_FAILED:
            case KvkUploadStatus.MUTATIONS_FAILED:
            case KvkUploadStatus.NEW_LINK_FAILED:
                return false;
            case KvkUploadStatus.MANUAL_SELECTION:
                if (this.kvkUploadRun) {
                    if (this.clRowsProcessed()) {
                        return false;
                    }
                }
                return true;
            default:
                return true;
        }
    }

    public clRowsProcessed() {
        return this.kvkUploadRun &&
                (!this.kvkUploadRun.clRows || this.kvkUploadRun.clRowsProcessed === this.kvkUploadRun.clRows);
    }

    public isManualRowsProcessed() {
        return this.kvkUploadRun && (!this.kvkUploadRun.manualRows ||
                    this.kvkUploadRun.manualRowsProcessed === this.kvkUploadRun.manualRows);
    }

    public get showButton() {
        switch (this.kvkUploadRun?.status) {
            case KvkUploadStatus.CONFIRM_UPLOADD_FAILED:
            case KvkUploadStatus.MUTATIONS_FAILED:
            case KvkUploadStatus.NEW_LINK_FAILED:
                return true;
        }
        switch (this.step) {
            case KvkStep.UPLOAD:
                return this.status <= KvkUploadStatus.CONFIRM_UPLOAD_IN_PROGRESS;
            case KvkStep.MUTATIONS:
                return this.status <= KvkUploadStatus.MUTATIONS_DONE;
            case KvkStep.NEW:
                return this.status <= KvkUploadStatus.NEW_LINK_DONE;
        }
        return true;
    }

    public get advanceStatusText() {
        const next = 'Verder';
        switch (this.step) {
            case KvkStep.UPLOAD:
                if (this.status === KvkUploadStatus.CONFIRM_UPLOADD_FAILED) {
                    return 'Upload goedkeuren (herstart)';
                }
                return this.status <= KvkUploadStatus.UPLOAD_DONE ? 'Upload goedkeuren' : next;
            case KvkStep.MUTATIONS:
                if (this.status === KvkUploadStatus.CONFIRM_UPLOAD_DONE) {
                    return 'Mutaties verwerken';
                }
                if (this.status === KvkUploadStatus.MUTATIONS_FAILED) {
                    return 'Mutaties verwerken (herstart)';
                }
                return this.status <= KvkUploadStatus.MUTATIONS_DONE ? 'Bevestig en ga verder' : next;
            case KvkStep.NEW:
                if (this.status === KvkUploadStatus.NEW_LINK_FAILED) {
                    return 'Nieuwe inschrijvingen verwerken (herstart)';
                }
                return this.status <= KvkUploadStatus.AUTO_NEW_DONE ? 'Bevestig en ga verder' : next;
            case KvkStep.MANUAL_CHECK:
                return 'Afronden';
        }
        return next;
    }

    public setNextStep() {
        switch (this.step) {
            case KvkStep.UPLOAD:
                this.kvkUploadRun!.selectedStep = KvkStep.MUTATIONS;
                break;
            case KvkStep.MUTATIONS:
                this.kvkUploadRun!.selectedStep = KvkStep.NEW;
                break;
            case KvkStep.NEW:
                this.kvkUploadRun!.selectedStep = KvkStep.MANUAL_CHECK;
                break;
            case KvkStep.MANUAL_CHECK:
                // cannot set next step as this is the last step.
                break;
        }
    }

    public async revert(confirmedRevert: boolean) {
        if (!confirmedRevert) {
            this.confirmRevertDialog = true;
        } else {
            this.confirmRevertDialog = false;
            this.loadingRevert = true;
            await this.$api.kvkUploadRun.cancel(this.kvkUploadRun!);
            this.loadingRevert = false;
            this.$router.push({name: 'kvk_upload_run_list'});
        }
    }
}
