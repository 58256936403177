import {GenericApi, IWrapperResponse} from '@/api/generic';
import {IKvkSignal} from '@/models/IKvkSignal';
import {get, url} from '@/utils/http';
import {IKvkSignalServicesStatusInfo} from '@/models/IKvkSignalServicesStatusInfo';

export class KvkSignalApi extends GenericApi<IKvkSignal> {
    public async getServicesStatusInfo() {
        const uri = new URL(url(this.path + `/service-info`));
        const result = await get(uri.href);
        return result.data as IWrapperResponse<IKvkSignalServicesStatusInfo>;
    }

    public async fetchLists() {
        const uri = new URL(url(this.path + `/fetch-lists/100`));
        const result = await get(uri.href);
        return result.data as IWrapperResponse<string>;
    }

    public async fetchDetails() {
        const uri = new URL(url(this.path + `/fetch-details/100000`));
        const result = await get(uri.href);
        return result.data as IWrapperResponse<string>;
    }

    public async fetchVestigingen() {
        const uri = new URL(url(this.path + `/fetch-vestigingen/100000`));
        const result = await get(uri.href);
        return result.data as IWrapperResponse<string>;
    }

    public async createCma() {
        const uri = new URL(url(this.path + `/create-cma`));
        const result = await get(uri.href);
        return result.data as IWrapperResponse<string>;
    }

    public async abortService() {
        const uri = new URL(url(this.path + `/abort-service`));
        const result = await get(uri.href);
        return result.data as IWrapperResponse<string>;
    }

    public async startKvkUploadRun(endDateTimestamp: number) {
        const uri = new URL(url(this.path + `/start-kvk-upload-run/${endDateTimestamp}`));
        const result = await get(uri.href);
        return result.data as IWrapperResponse<string>;
    }
}
