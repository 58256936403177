import {GenericApi, IWrapperResponse} from '@/api/generic';
import {Idable} from '@/models/Idable';
import {get, http, mergeURLSearchParams, post, url} from '@/utils/http';
import {IPagination, Pagination} from '@/utils/api-tools/pagination';
import {IPaginationResponse} from '@/utils/api-tools/pagination-response';
import {IKvk} from '@/models/IKvk';
import {IKvkUploadRun} from '@/models/IKvkUploadRun';

export class KvkUploadRunApi extends GenericApi<IKvkUploadRun> {

    constructor() {
        super('/api/v1/kvk-upload-run');
    }

    public async cancel(model: Idable) {
        const uri = new URL(url(this.path + `/${model.id}/cancel`));
        const result = await http.get(uri.href);
        return result.data as IWrapperResponse<boolean>;
    }

    public async advanceStatus(id: number) {
        const uri = new URL(url(this.path + `/${id}/advance`));
        const result = await http.get(uri.href);
        return result.data as IWrapperResponse<boolean>;
    }

    public async abortStatus(id: number) {
        const uri = new URL(url(this.path + `/${id}/abort`));
        const result = await http.get(uri.href);
        return result.data as IWrapperResponse<boolean>;
    }

    public async approveUpload(id: number) {
        const uri = new URL(url(this.path + `/${id}/approve-upload`));
        const result = await http.get(uri.href);
        return result.data as IWrapperResponse<boolean>;
    }

    public async processMutations(id: number) {
        const uri = new URL(url(this.path + `/${id}/process-mutations`));
        const result = await http.get(uri.href);
        return result.data as IWrapperResponse<boolean>;
    }

    public async approveMutations(id: number) {
        const uri = new URL(url(this.path + `/${id}/approve-mutations`));
        const result = await http.get(uri.href);
        return result.data as IWrapperResponse<boolean>;
    }

    public async approveAutomaticProcessing(id: number) {
        const uri = new URL(url(this.path + `/${id}/approve-automatic-processing`));
        const result = await http.get(uri.href);
        return result.data as IWrapperResponse<boolean>;
    }

    public async finishAutomaticProcessing(id: number) {
        const uri = new URL(url(this.path + `/${id}/finish-automatic-processing`));
        const result = await http.get(uri.href);
        return result.data as IWrapperResponse<boolean>;
    }

    public async finishManualProcessing(id: number) {
        const uri = new URL(url(this.path + `/${id}/finish-manual-processing`));
        const result = await http.get(uri.href);
        return result.data as IWrapperResponse<boolean>;
    }

    public async import(uploadRun: IKvkUploadRun) {
        const uri = new URL(url(this.path + `/import`));
        const formData = new FormData();
        formData.append('file', (uploadRun as any).file);
        formData.append('datumMutaties', '' + uploadRun.datumMutaties);
        formData.append('jaar', '' + uploadRun.jaar);
        formData.append('maand', '' + uploadRun.maand);
        if (uploadRun.register?.id) {
            formData.append('registerId', '' + uploadRun.register?.id);
        }
        const result = await post(uri.href, formData);
        return result.data as IWrapperResponse<Idable>;
    }
}
